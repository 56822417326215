module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"frontendverse","start_url":"/","background_color":"rgb(0, 126, 134)","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1034e0e4f9738a78148df6a5f6854830"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
